<!-- 产品信息 -->
<template>
  <div>
    <div class="header">
      <div>
        <h3>{{ text3 }}</h3>
      </div>
    </div>
    <div class="content_box">
      <div class="content">
        <h3>{{ text1 }}</h3>
        <p>
          {{ text2 }}
        </p>
        <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/chanpin/xinxi.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import { mapState } from "vuex";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "M108单抗项目介绍",
          text2:
            "M108单抗注射液是明济生物自主研发的针对靶点Claudin 18.2癌症肿瘤抗原高表达的ADCC增强型单克隆抗体。通过增强型的ADCC效应充分调动患者机体的免疫细胞来杀伤肿瘤细胞。在体外和体内的抗体药效及理化性质等检测中，M108单抗注射液展示了较高的靶点特异性结合、较强的抗肿瘤活性和很好的安全性。目前该项目已获得CDE下发的临床批准通知书，我们期待通过严格的临床试验，证明M108单抗注射液对肿瘤患者的安全性和有效性，为广大肿瘤患者提供新的治疗手段。",
          text3: "产品管线及进展",
        };
        break;
      case "en":
        return {
          text1: "M108 single antibody project introduction",
          text2:
            "M108 McAb injection is an ADCC enhanced monoclonal antibody independently developed by Mingji biology against the high expression of claudin 18.2 cancer tumor antigen. Through the enhanced ADCC effect, fully mobilize the immune cells of patients to kill tumor cells. In the detection of antibody efficacy and physicochemical properties in vitro and in vivo, M108 monoclonal antibody injection showed high target specific binding, strong antitumor activity and good safety. At present, the project has obtained the clinical approval notice issued by CDE. We look forward to proving the safety and effectiveness of M108 monoclonal antibody injection for tumor patients through strict clinical trials, so as to provide new treatment methods for tumor patients.",
          text3: "Product pipeline and progress",
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
};
</script>

<style lang="less" scoped>
.header {
  position: relative;
  height: 400px;
  background: url("https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/chanpin/header.png") no-repeat;
  background-size: 100% 100%;
  div {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #fff;
      padding-top: 20%;
    }
    h3::after {
      position: absolute;
      top: 110%;
      left: 0%;
      content: "";
      width: 50px;
      height: 2px;
      background-color: #fff;
    }
  }
}
.content_box {
  width: 100%;
  margin: 0 auto;
  background-color: rgba(204, 204, 204, 0.2);
  .content {
    width: 100%;
    max-width: 712px;
    margin: 0 auto;
    h3 {
      position: relative;
      font-size: 50px;
      color: #203864;
      font-weight: normal;
      padding-top: 150px;
      margin-bottom: 82px;
      text-align: center;
    }
    h3::after {
      position: absolute;
      top: 118%;
      left: 48%;
      width: 50px;
      height: 2px;
      content: "";
      background-color: #ccc;
    }
    p {
      color: #4a4a4a;
      font-size: 16px;
      line-height: 32px;
      margin-bottom: 40px;
    }
    img {
      margin-bottom: 150px;
    }
  }
}

@media screen and (max-width: 750px) {
  .header {
    height: 150px;
    div {
      h3 {
        font-size: 24px;
        margin-left: 10%;
        font-weight: normal;
        padding-top: 20%;
      }
      h3::after {
        width: 30px;
      }
    }
  }
  .content_box {
    .content {
      width: 100%;
      max-width: 712px;
      margin: 0 auto;
      padding: 20px;
      box-sizing: border-box;
      h3 {
        font-size: 30px;
        padding-top: 40px;
        margin-bottom: 52px;
      }
      h3::after {
        left: 40%;
      }
      p {
        width: 90vw;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 14px;
      }
      img {
        width: 90vw;
        margin-bottom: 30px;
      }
    }
  }
}
</style>
